<template>
  <div class="graduates-work">
    <div class="container">
      <div class="info">
        <div class="content">
          <h3 class="subtitle">
            {{ home ? $c.text('component.graduateswork.title2') : $c.text('component.graduateswork.title') }}
          </h3>
          <div v-if="$config.platform === 'ds'" class="logos">
            <LogoTesla />
            <LogoWalmart />
            <LogoCitibank />
            <LogoBooking />
            <LogoPaypal />
          </div>
          <div v-else class="logos">
            <LogoBarclays />
            <LogoBnpParibas />
            <LogoJpMorgan />
            <LogoGoldmanSachs />
            <LogoBankOfAmerica />
          </div>
          <div class="button row">
            <div class="col col-6">
              <nuxt-link
                to="/signup/" class="btn-primary btn-lg" :class="$config.platform === 'ds' ? 'btn-primary' : 'btn-primary-light'"
                :data-event="home === 'true' ? 'start_your_career_2.click' : 'start_your_career.click'"
              >
                {{ $c.text($config.platform === 'ds' ? 'button.start-your-career' : 'button.get-started') }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$config.platform === 'ds'" class="background" style="--ratio: calc(1170 / 393);">
        <img loading="lazy" src="../assets/images/start-your-career.webp" alt="Start your career">
      </div>
    </div>
  </div>
</template>

<script>
import LogoBooking from '../assets/svg/companies/logo-booking.svg?vue-component';
import LogoCitibank from '../assets/svg/companies/logo-citibank.svg?vue-component';
import LogoPaypal from '../assets/svg/companies/logo-paypal.svg?vue-component';
import LogoTesla from '../assets/svg/companies/logo-tesla.svg?vue-component';
import LogoWalmart from '../assets/svg/companies/logo-walmart.svg?vue-component';
import LogoBarclays from '../assets/svg/companies/logo-barclays.svg?vue-component';
import LogoBnpParibas from '../assets/svg/companies/logo-bnp-paribas.svg?vue-component';
import LogoJpMorgan from '../assets/svg/companies/logo-jp-morgan.svg?vue-component';
import LogoGoldmanSachs from '../assets/svg/companies/logo-goldman-sachs.svg?vue-component';
import LogoBankOfAmerica from '../assets/svg/companies/logo-bank-of-america.svg?vue-component';

export default {
  components: {
    LogoBooking,
    LogoCitibank,
    LogoPaypal,
    LogoTesla,
    LogoWalmart,
    LogoBarclays,
    LogoBnpParibas,
    LogoJpMorgan,
    LogoGoldmanSachs,
    LogoBankOfAmerica,
  },
  props: {
    home: Boolean,
  },
};
</script>

<style lang="scss">
.graduates-work {
  text-align: center;

  .container {
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    .info {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .content {
        width: 100%;

        .subtitle {
          margin-top: 0;
          color: var(--main-light);
          text-align: center;
        }

        .logos {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-evenly;
          max-width: 650px;
          margin: 0 auto;

          svg {
            margin: var(--spacing-8) 0 var(--spacing-20);
            max-width: 180px;
          }
        }

        .button {
          display: flex;
          justify-content: center;

          .col {
            padding: 0 20px;
          }

          a {
            width: 100%;
            max-width: 300px;
          }
        }
      }
    }

    > .background {
      z-index: -1;

      img {
        display: block;
      }
    }
  }
}

@include bp($bp-laptop) {
  .graduates-work .container .info .content .button a {
    max-width: 225px;
  }
}

@include bp($bp-mobile) {
  .site-section.graduates-work {
    overflow: hidden;
    padding: 0;

    .container {
      padding: var(--spacing-60) 0;
      border-radius: 0;

      > .background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }

      .info {
        padding: 0 24px;
        position: relative;

        .content {
          .subtitle {
            font-size: 21px;
          }

          .logos {
            margin: var(--spacing-24) auto var(--spacing-48);

            svg {
              width: 160px;
              margin: 11px 13px;
            }
          }

          .button {
            a {
              max-width: 350px;
            }
          }
        }
      }
    }
  }

  .fa {
    .site-section.graduates-work {
      .container {
        padding: 0 0 var(--spacing-24);

        .info {
          padding: var(--spacing-50) 0;

          .content {
            .button {
              a {
                max-width: 327px;
              }
            }

            .logos {
              max-width: 650px;
              margin-bottom: var(--spacing-16);
              margin-top: var(--spacing-20);

              :nth-child(-n+5) {
                margin-right: 0;
                margin-left: 0;
              }

              :nth-child(1) {
                width: 116px;
                height: 20px;
                margin-left: var(--spacing-140);
                margin-right: var(--spacing-40);
                margin-top: 0;
              }

              :nth-child(2) {
                width: 127px;
                height: 26px;
                margin-top: 0;
                margin-right: var(--spacing-140);
              }

              :nth-child(3) {
                width: 112px;
                height: 24px;
                margin-right: var(--spacing-60);
              }

              :nth-child(4) {
                width: 74px;
                height: 28px;
                margin-right: var(--spacing-60);
              }

              :nth-child(5) {
                width: 236px;
                height: 24px;
              }
            }
          }
        }
      }
    }
  }
}

@include bp($bp-phone) {
  .site-section.graduates-work {
    .container {
      .info {
        .content {
          .logos {
            svg {
              max-width: 83px;
            }
          }
        }
      }
    }
  }

  .fa {
    .site-section.graduates-work {
      .container {
        padding: 0 0 var(--spacing-24);

        .info {
          .content {
            .logos {
              :nth-child(1) {
                margin-right: var(--spacing-20);
                margin-left: var(--spacing-20);
                margin-bottom: var(--spacing-16);
              }

              :nth-child(2) {
                margin-right: var(--spacing-20);
                margin-bottom: var(--spacing-16);
              }

              :nth-child(3) {
                margin-right: var(--spacing-32);
                margin-top: 0;
              }

              :nth-child(4) {
                margin-top: 0;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.fa {
  .graduates-work .container .info {
    position: relative;
    padding: var(--spacing-60) 0;
    background: var(--accent);

    .content {
      .button a {
        margin-top: var(--spacing-16);
      }

      .subtitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        text-transform: uppercase;
        color: var(--accent-light-1);
      }

      .logos {
        justify-content: center;
        max-width: unset;

        :nth-child(1) {
          width: 214px;
          height: 36px;
          margin-right: var(--spacing-40);
          margin-bottom: var(--spacing-26);
          margin-left: 250px;
        }

        :nth-child(2) {
          width: 233px;
          height: 48px;
          margin-right: 250px;
          margin-bottom: var(--spacing-26);
        }

        :nth-child(3) {
          width: 206px;
          height: 44px;
          margin-right: var(--spacing-60);
          margin-top: 0;
        }

        :nth-child(4) {
          width: 136px;
          height: 52px;
          margin-right: var(--spacing-60);
          margin-top: 0;
        }

        :nth-child(5) {
          width: 434px;
          height: 44px;
          margin-top: 0;
        }

        svg {
          max-width: unset;
        }
      }
    }
  }
}
</style>
