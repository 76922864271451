<template>
  <div
    ref="lottie" class="lottie-animation" :class="{ shadow: options.shadow, 'play-on-scroll': playOnScroll }"
    :style="style"
  />
</template>

<script>
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable no-undef */
import lottie from 'lottie-web';

export default {
  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({
        loop: true,
        autoplay: true,
        pauseOnHover: false,
        playOnScroll: true,
      }),
    },
    height: { type: Number, default: 0, required: false },
    width: { type: Number, default: 0, required: false },
    ratio: { type: String, default: '', required: false },
  },
  data() {
    return {
      style: {
        width: this.width ? `${this.width}px` : '100%',
        height: this.height ? `${this.height}px` : '100%',
        overflow: 'hidden',
      },
      anim: null,
    };
  },
  computed: {
    playOnScroll() {
      return this.options.playOnScroll || typeof this.options.playOnScroll === 'undefined';
    },
  },
  mounted() {
    if (!this.options.lazyLoad) {
      this.loadAnim();
    } else {
      setTimeout(() => {
        this.loadAnim();
      }, 1500);
    }
    this.$emit('animCreated', this.anim);
    if (this.options.autoAlign) {
      this.$refs.lottie.childNodes[0].removeAttribute('height');
      this.$refs.lottie.childNodes[0].removeAttribute('width');
      this.$refs.lottie.childNodes[0].style.height = 'auto';
      this.$refs.lottie.childNodes[0].style.width = 'auto';
    }
    if (this.options.pauseOnHover) {
      if (window.innerWidth >= this.$store.state.breakPointLayoutResolution) {
        this.$refs.lottie.addEventListener('mouseenter', () => {
          lottie.freeze();
        });
        this.$refs.lottie.addEventListener('mouseleave', () => {
          lottie.unfreeze();
        });
      } else {
        const { classList } = this.$refs.lottie;
        this.$refs.lottie.addEventListener('click', () => {
          if (classList.contains('freezed')) {
            lottie.unfreeze();
            classList.remove('freezed');
          } else {
            lottie.freeze();
            classList.add('freezed');
          }
        });
        this.$refs.lottie.addEventListener('mouseleave', () => {
        });
      }
    }
  },
  beforeDestroy() {
    if (this.anim) {
      this.anim.destroy();
    }
  },
  methods: {
    async loadAnim() {
      const autoplay = this.playOnScroll ? false : this.options.autoplay || true;
      const file = await import(`../assets/animations/${this.options.data}.json`);

      this.anim = lottie.loadAnimation({
        container: this.$refs.lottie,
        renderer: 'svg',
        loop: this.options.loop || true,
        autoplay,
        animationData: JSON.parse(JSON.stringify(file.default)),
        rendererSettings: {
          ...this.options.rendererSettings,
        },
      });
      this.$refs.lottie.id = this.anim.animationID;
    },
  },
};
</script>

<style lang="scss">
.lottie-animation.shadow {
  &:before,
  &:after {
    content: '';
    height: var(--spacing-160);
    position: absolute;
    left: 0;
    right: 0;
  }

  &:before {
    background: linear-gradient(180deg, var(--main-dark) 0%, rgba(0, 12, 31, 0.76) 37.5%, rgba(0, 12, 31, 0) 100%);
    top: 0;
    z-index: 1;
  }

  &:after {
    background: linear-gradient(180deg, var(--main-dark) 0%, rgba(0, 12, 31, 0.7) 41.67%, rgba(0, 12, 31, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    bottom: 0;
    z-index: 1;
  }
}

@include bp($bp-mobile) {
  .lottie-animation.shadow:before {
    top: -1px;
  }
}

@include bp($bp-phone) {
  .lottie-animation.shadow {
    width: 152% !important;
    margin-left: -52%;
  }
}
</style>
