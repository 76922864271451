<template>
  <section class="profile-options">
    <component :is="heading" class="title h4">
      {{ firstStepTitle || title }}
    </component>
    <small v-if="$store.state.auth.loginAlert" class="alert-error">Please use your company email address to log in to your business account.</small>
    <div class="body">
      <div class="action-btn">
        <GoogleLoginButton
          :form-type="formType"
          :button-text="buttonTexts.google"
          :data-event="`${eventPrefix}${['signup', 'login'].includes(tracking) ? 'google.click' : 'sign_up_form_google.click'}`"
          terms-agreed
          data-async-event
          data-direct-send
          :resources="resources"
        />
      </div>
      <div class="action-btn">
        <FacebookLoginButton
          :form-type="formType"
          :button-text="buttonTexts.facebook"
          :data-event="`${eventPrefix}${['signup', 'login'].includes(tracking) ? 'facebook.click' : 'sign_up_form_facebook.click'}`"
          terms-agreed
          data-async-event
          data-direct-send
          :resources="resources"
        />
      </div>
      <div class="action-btn">
        <LinkedinLoginButton
          :form-type="formType"
          :button-text="buttonTexts.linkedin"
          :data-event="`${eventPrefix}${['signup', 'login'].includes(tracking) ? 'linkedin.click' : 'sign_up_form_linkedin.click'}`"
          terms-agreed
          data-async-event
          data-direct-send
          :resources="resources"
        />
      </div>
      <div class="action-btn">
        <button
          :data-event="`${eventPrefix}${['signup', 'login'].includes(tracking) ? 'login_continue.click' : 'login_continue.click'}`"
          data-async-event
          data-direct-send @click="$emit('next-step')"
        >
          <IconMail /> Continue with email
        </button>
      </div>
    </div>

    <div class="terms">
      By continuing, you agree to {{ $config.platformName }}’s
      <a target="_blank" :href="`${$config.siteUrl}/terms-of-use/`">Terms of Use</a>
      and
      <a target="_blank" :href="`${$config.siteUrl}/privacy-policy/`">Privacy Policy</a>
    </div>

    <slot class="footer-test" name="footer" />
  </section>
</template>

<script>
import IconMail from '@itbpbg/lms-components/src/assets/svg/icon-mail.svg?vue-component';
import GoogleLoginButton from '../GoogleLoginButton';
import FacebookLoginButton from '../FacebookLoginButton';
import LinkedinLoginButton from '../LinkedinLoginButton';

export default {
  components: {
    GoogleLoginButton,
    FacebookLoginButton,
    LinkedinLoginButton,
    IconMail,
  },
  props: {
    firstStepTitle: { type: String, required: false, default: '' },
    title: { type: String, required: true },
    heading: { type: String, default: 'h2' },
    type: { type: String, default: '' },
    tracking: { type: String, default: '' },
    resources: Boolean,
    eventPrefix: { type: String, default: '' },
  },
  data() {
    return {
      formType: this.type || this.$route.name,
      buttonTexts: {
        google: 'Continue with Google',
        facebook: 'Continue with Facebook',
        linkedin: 'Continue with LinkedIn',
      },
    };
  },
};
</script>

<style lang="scss">
.profile-options {
  .title {
    text-align: center;
    margin-bottom: var(--spacing-28);
  }

  .body {
    margin-bottom: var(--spacing-32);

    .action-btn {
      border: 1px solid rgba(0, 12, 31, 0.18);
      border-radius: 5px;

      &:not(:last-child) {
        margin-bottom: var(--spacing-12);
      }

      &:hover {
        background-color: var(--main-light-shade-1);
        border: 1px solid var(--main-dark-opacity-12);
      }

      > button {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        width: 100%;
        font-size: 100%;
        font-family: inherit;
        line-height: normal;
        cursor: pointer;

        > svg {
          width: 24px;
          padding-left: 2px;
          padding-right: 2px;

          > path[stroke] {
            stroke: var(--main-dark-light-3);
          }
        }
      }

      > a,
      a.visible,
      button {
        display: flex;
        align-items: center;
        padding: var(--spacing-14) var(--spacing-44);
        line-height: unset;
        font-size: 18px;

        > svg {
          margin-right: 15px;
          width: 24px;
        }
      }

      .invisible {
        overflow: hidden;
      }
    }
  }

  .terms {
    font-size: 14px;
    color: rgba(0, 12, 31, 0.48);
    font-weight: 500;
    line-height: 130%;
    margin-bottom: var(--spacing-24);
  }

  .footer {
    color: rgba(0, 12, 31, 0.48);
    text-align: center;
    line-height: 150%;
  }

  .alert-error {
    position: static;
    text-align: center;
    display: block;
    margin-top: var(--spacing-8);
    height: auto;
    background-color: var(--main-red-light-2);
    border: 1px solid var(--main-red-light-3);
    border-radius: 5px;
    color: var(--main-red-shade-1);
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    padding: 12px 30px;
    margin-bottom: var(--spacing-12);
  }
}

@include bp($bp-laptop) {
  .profile-options {
    .body {
      .action-btn {
        > a,
        a.visible,
        button {
          padding: var(--spacing-14) var(--spacing-24);
        }
      }
    }
  }
}

@include bp($bp-phone) {
  .profile-options {
    .body {
      .action-btn {
        > a,
        a.visible,
        button {
          text-align: left;
          padding: 17px 30px;
          height: 52px;

          svg {
            flex-shrink: 0;
          }
        }
      }
    }
  }
}
</style>
