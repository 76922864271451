<template>
  <div>
    <span ref="signinBtn" class="invisible" />
    <a
      href="#social-login"
      title="Login with Google"
      rel="nofollow"
      :class="{ visible: termsAgreed || formType === 'login' }"
    >
      <IconGoogle /> {{ buttonText }}
    </a>
    <a
      href="#social-login"
      title="Sign up with Google"
      rel="nofollow"
      class="fake"
      :class="{ visible: !termsAgreed && formType === 'signup' }"
      @click.prevent="checkTerms"
    >
      <IconGoogle /> {{ buttonText }}
    </a>
  </div>
</template>

<script>
import IconGoogle from '../assets/svg/icon-colored-google.svg?vue-component';

export default {
  components: {
    IconGoogle,
  },
  props: {
    keepMeLoggedIn: Boolean,
    errored: Boolean,
    campaign: Boolean,
    termsAgreed: Boolean,
    resources: Boolean,
    formType: { type: String, default: 'login' },
    buttonText: { type: String, default: 'Google' },
    flashcards: Boolean,
  },
  async mounted() {
    await this.$pollValue(() => typeof window.google !== 'undefined');
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: this.$config.googleApiKey,
        callback: this.handleCredentialResponse,
      });
      // window.google.accounts.id.prompt();

      window.google.accounts.id.renderButton(this.$refs.signinBtn, {
        width: 400,
        size: 'large',
      });
    }
  },
  methods: {
    checkTerms() {
      this.$root.$emit('check-terms', true);
    },
    async handleCredentialResponse(response) {
      if (this.formType === 'signup') {
        this.checkTerms();
      }
      const { event } = this.$el.dataset;

      const token = response.credential;
      await this.$root.$emit('check');
      if (!this.errored) {
        const keepMeLoggedIn = !!this.keepMeLoggedIn;
        const beta = !!this.$config.gateway;
        const request = await this.$axios
          .$post(
            `/auth/social/google?keepMeLoggedIn=${keepMeLoggedIn}&beta=${beta}&campaign=${this.campaign}`,
            { token, course: this.$route.query.course },
          )
          .catch((e) => e.response.data);

        if (request && request.businessAccount) {
          this.$store.dispatch('auth/setUserInfo', request);
        }
        if (request && request.user) {
          if (request.user.registered) {
            this.$tracking.setSignupEvent({ signupType: 'Google', directSend: this.flashcards, userId: request.user.id });
          }

          this.$store.dispatch('auth/setUserInfo', request.user);
          if (this.resources) {
            this.$emit('continueToExamResult');
            return;
          }
          if (this.campaign) {
            this.$root.$emit('social-login-success');
          } else {
            await this.$tracking.sendEvent({
              event: 'google.click.success',
              route: this.$route,
              destinationUrl: this.$route.fullPath,
              sourceUrl: this.$route.fullPath,
            });

            console.log('🚀 ~ this.flashcards', this.flashcards);

            console.log('🚀 ~ this.$route.query.course', this.$route.query.course);
            if (this.$route.query.course) {
              if (this.$store.getters['auth/user'].simpleLms) {
                this.$redirectAfterLogin('/');
              } else {
                this.$redirectAfterLogin('/courses/?tab=my-courses');
              }
            } else if (!this.flashcards) {
              console.log('redirect after signup', this.$route.query);
              this.$redirectAfterLogin('/my-learning-path/');
            }
          }
        }

        if (request && request.error) {
          this.$tracking.sendAsyncEvent(event, {
            status: 'fail',
            error: request.description || request.error,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.invisible {
  opacity: 0.01;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

div {
  position: relative;

  ::v-deep iframe {
    position: absolute !important;
    width: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    margin: 0 !important;
    min-height: 52px !important;
  }

  ::v-deep div {
    height: 50px !important;
  }

  ::v-deep [id^="gsi_"][id$="-wrapper"] {
    top: -50px !important;
  }

  a {
    border-radius: 0;
    color: var(--main-dark-shade-0);
    display: block;
    line-height: 52px;
    text-align: center;
    transition: none;
    width: 100%;

    &.fake.visible {
      position: relative;
      z-index: 1;
    }

    &:not(.visible) {
      visibility: hidden;
      opacity: 0.01;
      top: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }

    svg {
      vertical-align: sub;
      margin-right: var(--spacing-14);
    }
  }
}
</style>
