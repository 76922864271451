<template>
  <div class="instructors-cards container">
    <Slider v-if="instructors.length">
      <h2 slot="heading" class="h3 col-11">
        Take data science courses by world-class experts like Bernard Marr, Danielle Thé, Ken Jee, and more.
      </h2>
      <div
        v-for="(slide, i) in slides"
        :key="i"
        slot="slide"
        class="col col-4"
      >
        <nuxt-link
          v-for="(v, idx) in slide" :key="idx" :to="`/instructors/${v.userSlug}/`"
          class="card"
          :data-event="`instructors_${(v.userSlug || '').replace(/-/g, '_')}.click`"
        >
          <div class="image">
            <img loading="lazy" :src="`${$config.imagesUrl}/team/thumb@360_${v.image}`" :alt="v.name">
          </div>
          <div class="info">
            <p class="h6">
              {{ v.name }}
            </p>
            <p class="body-2">
              {{ v.university }}
            </p>
          </div>
        </nuxt-link>
      </div>
    </Slider>
  </div>
</template>

<script>
import Slider from '@itbpbg/lms-components/src/components/Slider';

export default {
  components: { Slider },
  props: {
    instructors: { type: Array, required: true },
  },
  computed: {
    slides() {
      return this.instructors.reduce((p, c, i) => {
        if (i % 4 === 0) {
          p.push([c]);
          return p;
        }

        p[p.length - 1].push(c);
        return p;
      }, []);
    },
  },
};
</script>

<style lang="scss" scoped>
.instructors-cards {
  margin-top: var(--spacing-110);
  margin-bottom: var(--spacing-100);

  .h3 {
    margin: 0;
  }

  .card {
    display: flex;
    background-color: var(--main-dark-shade-1);
    border-radius: 10px;
    align-items: center;
    margin-bottom: var(--spacing-16);
    color: var(--main-light);

    .info {
      padding: 0 var(--spacing-24);

      .h6 {
        margin: 0;
      }

      .body-2 {
        color: var(--main-light-shade-4);
        margin: var(--spacing-8) 0 0;
      }
    }

    .image {
      overflow: hidden;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      flex: 0 0 auto;
    }

    img {
      display: block;
      width: var(--spacing-100);
      height: var(--spacing-100);
      object-fit: cover;
      object-position: top center;
      transition: all var(--duration) ease-in;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}

@include bp($bp-tablet) {
  .instructors-cards {
    .col {
      max-width: 50%;
    }
  }
}
</style>
