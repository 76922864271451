<template>
  <div>
    <SignupOptions :class="className" v-bind="$props" />
    <!-- <SignupForm v-else :class="className" v-bind="$props" /> -->
  </div>
</template>

<script>
// import SignupForm from '../../SignupForm';
import SignupOptions from './SignupOptions';

export default {
  components: {
    // SignupForm,
    SignupOptions,
  },
  props: {
    tracking: { type: String, default: '' },
    title: { type: String, default: '' },
    firstStepTitle: { type: String, default: '' },
    version: { type: String, default: '0' },
    buttonText: { type: String, default: 'Sign Up' },
    buttonClass: { type: String, default: 'btn-primary' },
    className: { type: String, default: '' },
    type: { type: String, default: '' },
    uniqueId: { type: String, default: '' },
    eventPrefix: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
.start-learning {
  @include bp($bp-mobile) {
    &.signup-form {
      padding: 0;
      max-width: 420px;
      margin: 0 auto;
    }

    &.upcoming {
      background: var(--main-light-shade-1);

      .options {
        background: var(--main-light-shade-1);
      }

      .form-wrap {
        background: var(--main-light-shade-1);
      }
    }
  }
}

.fa {
  .start-learning {
    @include bp($bp-mobile) {
      &.upcoming {
        background: var(--main-light);

        .options {
          background: var(--main-light);
        }

        .form-wrap {
          background: var(--main-light);
        }
      }
    }
  }
}
</style>
