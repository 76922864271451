import { render, staticRenderFns } from "./InstructorsCards.vue?vue&type=template&id=9e4b5856&scoped=true&"
import script from "./InstructorsCards.vue?vue&type=script&lang=js&"
export * from "./InstructorsCards.vue?vue&type=script&lang=js&"
import style0 from "./InstructorsCards.vue?vue&type=style&index=0&id=9e4b5856&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e4b5856",
  null
  
)

export default component.exports