<template>
  <div>
    <Lazy when-idle>
      <div class="hero dark">
        <div class="container">
          <div class="row">
            <div class="left col col-5">
              <h1 class="h2">
                Learn <span class="block"><strong>Data Science</strong> and <strong class="yellow">AI</strong></span> with Industry Experts
              </h1>
              <p class="subtitle">
                Master future-proof skills to break into data science and AI. Empower your journey with online courses, projects, exams, and certificates of achievement.
              </p>
              <div class="rating-inline">
                <span class="light">4.9/5</span> <Rating :scale="4.9" :size="28" /> <span class="dark-light">(413,000+ Platform Reviews)</span>
              </div>
            </div>
            <div class="signup-wrapper col col-5 offset-2">
              <SignupFormVersion
                first-step-title="Create Your Free Account"
                title="Create Your Free Account"
                button-text="Start for Free"
                button-class="btn-primary-yellow"
                type="signup"
                unique-id="second"
                event-prefix="hero_"
                class="signup-form form-mobile"
              />
            </div>
          </div>
        </div>
      </div>
    </Lazy>
    <Lazy never>
      <div class="technologies dark">
        <div class="container">
          <div v-for="(tech) in technologies" :key="tech.icon" class="single-technology">
            <component
              :is="icon(tech.icon)"
              :class="tech.icon"
              :title="tech.text"
            />
            <span v-if="tech.text" class="text">
              {{ tech.text }}
            </span>
          </div>
        </div>
      </div>
    </Lazy>
    <Lazy :when-visible="{ rootMargin: '200px' }">
      <HomeSectionInfinite
        v-if="data.courses && data.courses.length"
        classes="courses dark"
        subtitle="Learn how to work with sought-after technologies like Excel, Python, SQL, Tableau, and ChatGPT. Build essential database management, data preprocessing, data visualization, analysis, and communication skills. 365 Data Science combines the latest technical capabilities with an evergreen business and analytical mindset so you can thrive in a data and AI-driven world."
        :sections="data.courses"
        section-slug="courses"
        infinite-fill
        data-event="courses.scroll"
        class="tracking-section"
      >
        <template slot="title">
          Expert-Led Data Science Courses
        </template>
        <nuxt-link
          slot="button" :to="`/courses/`" class="btn-primary btn-lg"
          data-event="courses.click"
        >
          Explore Courses
        </nuxt-link>
      </HomeSectionInfinite>
    </Lazy>
    <Lazy :when-visible="{ rootMargin: '200px' }">
      <section
        v-if="data.successStories && data.successStories.length" class="white slider container tracking-section"
        data-event="success-stories.scroll"
      >
        <div class="row">
          <div class="heading col col-8">
            <h3>Success Stories</h3>
            <p class="subtitle">
              Find motivation from the success stories of students who embarked on their data science careers with the guidance of the 365 program. Discover what ignited their passion for this field, their unique journeys, and the invaluable assistance provided by the program.
            </p>
          </div>
        </div>
        <Slider no-controllers>
          <div
            v-for="(story, idx) in data.successStories" :key="idx" slot="slide"
            class="col sm-6 col-3"
          >
            <nuxt-link :to="`/success-stories/${story.slug}`" :data-event="`success-stories_${story.slug}.click`">
              <div class="img">
                <img :src="`${$config.imagesUrl}/success-stories/${story.img}`" :alt="story.name" loading="lazy">
              </div>
              <p class="blog-post-title-2">
                {{ story.name }}
              </p>
              <p class="description">
                {{ story.description }}
              </p>
              <ButtonArrow
                type="span" text="Read story"
              />
            </nuxt-link>
          </div>
        </Slider>
      </section>
    </Lazy>
    <HomeSectionInfinite
      v-if="data.projects && data.projects.length"
      classes="projects dark"
      subtitle="Bridge the gap between theoretical knowledge and practical implementation by working on real-world data projects, and gather valuable experience for your portfolio. Start with beginner-friendly projects or explore advanced topics to challenge yourself."
      :sections="data.projects"
      section-slug="projects"
      data-event="projects_"
      class="tracking-section"
    >
      <template slot="title">
        Data Science Projects
      </template>
      <nuxt-link
        slot="button" :to="`/projects/`" class="btn-primary btn-lg"
        data-event="projects.click"
      >
        Explore Projects Library
      </nuxt-link>
    </HomeSectionInfinite>
    <section v-if="videos && videos.length" class="white slider container videos" data-event="videos.scroll">
      <div class="row">
        <div class="heading col col-8">
          <h3>Testimonials</h3>
          <p class="subtitle">
            365 Data Science is the best place to start your career journey. Discover what sets the platform apart from other educational providers from students who broke into the field with the help of our top-level data science training.
          </p>
        </div>
      </div>
      <Videos
        v-if="$config.platform === 'ds' && videoJSLoaded" :videos="videos" :video-js-loaded="videoJSLoaded"
        light
      />
    </section>
    <Lazy never>
      <section class="features dark tracking-section" data-event="features.scroll">
        <div class="container">
          <div class="row">
            <div class="heading col col-8 offset-2">
              <h3 class="title">
                <span class="left">What Is</span> <img src="../../assets/images/logo-extended.webp" alt="365DataScience">
              </h3>
              <p class="body-1">
                365 Data Science provides a holistic data science and AI training, including the technical expertise and soft skills needed to ensure long-term success in a dynamic job market. Our courses and projects are based on real-life use cases you can expect when you get hired. Verify your data and AI skills with industry-recognized certificates, build a powerful project portfolio, and go from beginner to job-ready.
              </p>
            </div>
          </div>
          <div class="bg" style="--ratio: 988/629;">
            <img
              src="../../assets/images/v2-home-features.webp" alt="What is 365DataScience"
              loading="lazy"
            >
          </div>
          <p class="h3 title-ul">
            What Can You Expect from the 365 Data Science Program?
          </p>
          <template v-if="features && features.length">
            <ul v-for="(group, idx) in featuresGroups" :key="idx" class="row">
              <li v-for="(f, fIdx) in group" :key="fIdx" class="col col-4">
                <component :is="getFeatureIcon(f.icon)" />
                <div>
                  <span class="h5">
                    {{ f.name }}
                  </span>
                  <span class="body-1">
                    {{ f.description }}
                  </span>
                </div>
              </li>
            </ul>
          </template>
          <div class="section-link">
            <nuxt-link to="/signup/" class="btn-primary btn-lg" data-event="features.click">
              Start Learning
            </nuxt-link>
          </div>
        </div>
      </section>
    </Lazy>
    <section class="white free-resources container">
      <div class="row">
        <div class="heading col col-8 offset-2">
          <h3 class="h2">
            Data Science Resources
          </h3>
          <p class="body-1">
            Discover free downloadable resources, fine-tuned statistical calculators, and information-rich articles that enhance your data science learning experience and provide invaluable knowledge on how to land a data science job.
          </p>
        </div>
      </div>
      <Lazy :when-visible="{ rootMargin: '200px' }">
        <div v-if="data.resources && data.resources.length" class="resources tracking-section" data-event="resources.scroll">
          <Slider :buffer="30" no-controllers>
            <h4 slot="heading" class="h3">
              Resources
            </h4>
            <nuxt-link
              slot="subtitle" to="/resources-center/" class="btn-link-accent desktop-only"
              data-event="resources.click"
            >
              See All Resources
            </nuxt-link>
            <div
              v-for="(resource, idx) in data.resources" :key="idx" slot="slide"
              class="col col-3 sm-6"
            >
              <nuxt-link :to="`/resources-center/${resource.categorySlug}/${resource.slug}/`" :data-event="`resources_${resource.slug}.click`">
                <div class="img" style="--ratio: 270/379;">
                  <img :src="`${$config.imagesUrl}/home/v2-resources-${resource.slug}.webp`" :alt="resource.name" loading="lazy">
                </div>
              </nuxt-link>
            </div>
          </Slider>
          <nuxt-link
            slot="subtitle" to="/resources-center/" class="btn-link-accent mobile-only"
            data-event="resources.click"
          >
            See All Resources
          </nuxt-link>
        </div>
      </Lazy>
      <Lazy :when-visible="{ rootMargin: '200px' }">
        <div v-if="calculators && calculators.length" class="calculators tracking-section" data-event="calculators.scroll">
          <Slider no-controllers :disabled="$store.state.layout === 'desktop' && calculators.length < 10">
            <h4 slot="heading" class="h3">
              Calculators and Tables
            </h4>
            <nuxt-link
              slot="subtitle" to="/calculators/confidence-interval-calculator/" class="btn-link-accent desktop-only"
              data-event="calculators.click"
            >
              See All Calculators
            </nuxt-link>
            <ul
              v-for="(group, idx) in calculatorsGroups" :key="idx" slot="slide"
              class="col col-4"
            >
              <li v-for="(calc, cIdx) in group" :key="cIdx">
                <nuxt-link :to="`/calculators/${calc.slug}`" :data-event="`calculators_${calc.slug}.click`">
                  <span>{{ calc.name }}</span>
                </nuxt-link>
              </li>
            </ul>
          </Slider>
          <nuxt-link
            slot="subtitle" to="/calculators/confidence-interval-calculator/" class="btn-link-accent mobile-only"
            data-event="calculators.click"
          >
            See All Calculators
          </nuxt-link>
        </div>
      </Lazy>
      <Lazy :when-visible="{ rootMargin: '200px' }">
        <div v-if="data.blog && data.blog.length" class="blog tracking-section" data-event="blog.scroll">
          <Slider no-controllers>
            <h4 slot="heading" class="h3">
              Blog
            </h4>
            <nuxt-link
              slot="subtitle" to="/blog/" class="btn-link-accent desktop-only"
              data-event="blog.click"
            >
              See All Articles
            </nuxt-link>
            <div
              v-for="(article, idx) in data.blog" :key="idx" slot="slide"
              class="col col-4"
            >
              <ArticleCard
                :article="article" heading="h5" data-event="blog_article.click"
                :not-lazy="false"
              />
            </div>
          </Slider>
          <nuxt-link
            slot="subtitle" to="/blog/" class="btn-link-accent mobile-only"
            data-event="blog.click"
          >
            See All Articles
          </nuxt-link>
        </div>
      </Lazy>
    </section>
    <StartLearningToday
      v-if="$config.project !== 'lms'"
      new-version
      class="start-learn-today tracking-section"
      data-event="start-learning-now.scroll"
      event-prefix="footer_"
      signup-title="Create Your Free Account"
    >
      <h4 class="h3">
        Start Learning <strong>Data Science</strong> Today
      </h4>
      <p class="body-1">
        Kickstart your career with 365 Data Science. Our program builds your knowledge from the ground up so that you thoroughly understand the subject matter. Become an invaluable asset to any team with high-quality data science and AI education.
      </p>
    </StartLearningToday>
  </div>
</template>

<script>
import Lazy from 'vue-lazy-hydration';
import { loadHotJar } from '@itbpbg/lms-components/src/utils/helper';
import ButtonArrow from '@itbpbg/lms-components/src/components/ButtonArrow';
import Videos from '@itbpbg/lms-components/src/Campaigns/Videos';
import Rating from '@itbpbg/lms-components/src/Rating';
import StartLearningToday from '../StartLearningToday';
import LogoExtended from '../../assets/svg/365-logo-extended.svg?vue-component';
import SignupFormVersion from '../Profile/Signup/SignupFormVersion';
import HomeSectionInfinite from './HomeSectionInfinite';

export default {
  components: {
    Lazy,
    Slider: () => import('@itbpbg/lms-components/src/components/Slider'),
    ButtonArrow,
    StartLearningToday,
    LogoExtended,
    SignupFormVersion,
    Rating,
    HomeSectionInfinite,
    Videos,
    ArticleCard: () => import('../Blog/ArticleCard'),
  },
  mixins: [global],
  props: {
    data: { type: Object, default: () => ({}), required: true },
  },
  data() {
    return {
      technologies: [
        { icon: 'python', text: 'Python' },
        { icon: 'sql' },
        { icon: 'excel', text: 'Excel' },
        { icon: 'tableau', text: 'Tableau' },
        { icon: 'powerbi', text: 'Power BI' },
        { icon: 'chatgpt', text: 'ChatGPT' },
        { icon: 'r' },
        { icon: 'jupyter', text: 'Jupyter' },
        { icon: 'git', text: 'Git and Github' },
      ],
      videos: [
        { name: 'Lee', from: 'USA', id: 6315230915112 },
        { name: 'Meghan', from: 'Canada', id: 6315228912112 },
        { name: 'Julián', from: 'Mexico', id: 6343656185112 },
        { name: 'Nada', from: 'Egypt', id: 6315228795112 },
        { name: 'Ernest', from: 'Kenya', id: 6315231920112 },
        { name: 'Mihaela', from: 'Bulgaria', id: 6341101204112 },
        { name: 'Ahmed', from: 'Egypt', id: 6327293863112 },
      ],
      videoJSLoaded: false,
      features: [
        {
          name: 'Custom Learning Path',
          icon: 'home-clp',
          description: 'Build the ideal learning journey for your desired job and strengthen your abilities with a personalized skill track.',
        },
        {
          name: 'Structured Career Tracks',
          icon: 'home-courses',
          description: 'Embark on a pre-established data science career path, choose the electives that best suit your needs, and obtain highly sought-after role-specific skills.',
        },
        {
          name: 'Hands-On Projects',
          icon: 'home-projects',
          description: 'Boost your portfolio with real-world data projects based on actionable use cases that provide valuable business insights.',
        },
        {
          name: 'Practical Exams',
          icon: 'home-exams',
          description: 'Test your newfound data science, programming, machine learning, and AI skills with our practice, course, and career track exams.',
        },
        {
          name: 'Certificates of Achievement',
          icon: 'home-certificates',
          description: 'Prove you’re a competent data professional by earning data science certificates recognized by employers.',
        },
        {
          name: 'Resume Builder',
          icon: 'home-resume',
          description: 'Create a sharp and compelling data science resume to grab the attention of recruiters and hiring managers with an information-rich summary and certification.',
        },
        {
          name: 'Social Channels',
          icon: 'home-channels',
          description: 'Build your professional network, exchange experiences with like-minded individuals, and receive support from your instructors.',
        },
        {
          name: 'Gamified Features',
          icon: 'home-gamification',
          description: 'Transform your data science learning into a motivating journey. Set daily goals, earn in-platform currency, collect exclusive cards, and unlock exciting rewards.',
        },
        {
          name: 'Competitive Leaderboard',
          icon: 'home-leaderboard',
          description: 'Rise in the weekly ranks through XPs and blow the competition away with your relentless determination to climb to the top.',
        },
      ],
      calculators: [
        {
          name: 'Confidence Interval Calculator',
          slug: 'confidence-interval-calculator',
        },
        {
          name: 'Mean, Median, Mode Calculator',
          slug: 'mean-median-mode-calculator',
        },
        {
          name: 'Skewness Calculator',
          slug: 'skewness-calculator',
        },
        {
          name: 'Kurtosis Calculator',
          slug: 'kurtosis-calculator',
        },
        {
          name: 'Variance Calculator',
          slug: 'variance-calculator',
        },
        {
          name: 'Standard Deviation Calculator',
          slug: 'standard-deviation-calculator',
        },
        {
          name: 'Coefficient of Variation Calculator',
          slug: 'coefficient-of-variation-calculator',
        },
        {
          name: 'Linear Regression Calculator',
          slug: 'linear-regression-calculator',
        },
        {
          name: 'P-Value Calculator',
          slug: 'p-value-calculator',
        },
      ],
    };
  },
  computed: {
    featuresGroups() {
      if (this.$store.state.layout !== 'desktop') {
        return [
          [...this.features.filter((v, i) => (i + 1) % 3 === 1)],
          [...this.features.filter((v, i) => (i + 1) % 3 === 2)],
          [...this.features.filter((v, i) => (i + 1) % 3 === 0)],
        ];
      }

      return [
        [...this.features.filter((v, i) => i < 3)],
        [...this.features.filter((v, i) => i >= 3 && i < 6)],
        [...this.features.filter((v, i) => i >= 6 && i < 9)],
      ];
    },
    calculatorsGroups() {
      return [
        [...this.calculators.filter((v, i) => i % 3 === 0)],
        [...this.calculators.filter((v, i) => i % 3 === 1)],
        [...this.calculators.filter((v, i) => i % 3 === 2)],
      ];
    },
  },
  mounted() {
    if (this.$config.platform === 'ds') {
      loadHotJar();
    }
    this.loadVideoJS();
    window.addEventListener('scroll', () => {
      this.loadVideoJS();
    }, this.$passiveEventCheck);
    this.$once('hook:beforeDestroy', () => window.removeEventListener('scroll', () => {
      this.loadVideoJS();
    }));

    // remove lazy loading
    setTimeout(() => {
      document.querySelectorAll('img[loading="lazy"]').forEach((el) => {
        el.setAttribute('loading', 'eager');
      });
    }, 7000);
  },
  methods: {
    icon(icon) {
      const name = icon === 'sql' ? icon : `tech-responsive-${icon}`;
      return icon ? require(`@itbpbg/lms-components/src/assets/svg/${name}.svg?vue-component`) : '';
    },
    getVideoThumb(image) {
      return require(`@itbpbg/lms-components/src/assets/images/campaigns/video-thumbs/vthumb-${image.toLowerCase()}.webp`);
    },
    async openVideo(videoId) {
      await this.$pollValue(() => typeof videojs !== 'undefined');
      this.$store.dispatch('modals/open', { name: 'video', videoId: videoId.toString() });
    },
    loadVideoJS() {
      const el = this.$el.querySelector('section.videos');
      if (el && el.getBoundingClientRect().top - window.innerHeight < 0 && !this.videoJSLoaded) {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://players.brightcove.net/6258000438001/FHDad0Z7G_default/index.min.js');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('async', true);
        document.body.appendChild(script);
        this.videoJSLoaded = true;
      }
    },
    getFeatureIcon(icon) {
      return require(`../../assets/svg/home/v2-${icon}.svg?vue-component`);
    },
  },
};
</script>

<style lang="scss" scoped>
.dark {
  background-color: var(--main-dark);
}

.btn-link-accent {
  font-weight: 600;
}

section {
  padding-top: 120px;
  padding-bottom: 120px;

  &.courses {
    padding-top: 80px;
  }

  &.container .heading {
    .subtitle {
      color: var(--main-dark-light-4);
    }
  }

  .section-link {
    margin-top: var(--spacing-60);
    display: flex;
    justify-content: center;

    .btn-primary {
      width: 100%;
      max-width: 300px;
    }
  }

  &.videos {
    .graduates-thoughts {
      padding: 0;

      ::v-deep .videos .single-video .name {
        color: var(--main-dark);
      }

      ::v-deep .slider-container {
        margin-top: 0;

        .slider-header {
          margin-bottom: var(--spacing-32);
        }
      }
    }
  }
}

@include bp($bp-desktop) {
  .mobile-only {
    display: none !important;
  }

  section.free-resources {
    padding-bottom: 160px;
  }
}

.hero {
  background-image: url('../../assets/images/home-v2-bg-hero.webp');
  background-size: auto 100%;
  background-position: center;
  min-height: 700px;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 80px;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    background: linear-gradient(180deg, #000C1F 0%, rgba(0, 12, 31, 0) 100%);
  }

  .row {
    align-items: center;
  }

  .left {
    h1 {
      color: var(--main-light);

      strong {
        color: var(--accent);

        &.yellow {
          color: var(--main-yellow);
        }
      }

      .block {
        display: block;
      }
    }

    .subtitle {
      color: var(--main-light-shade-4);
      margin: var(--spacing-16) 0 var(--spacing-40);
    }

    .rating-inline {
      display: flex;
      align-items: center;

      .light {
        color: var(--main-light);
      }

      .dark-light {
        font-size: 14px;
        color: var(--main-dark-shade-4);
      }

      .rating {
        margin: 0 var(--spacing-4);
      }
    }
  }

  .signup-wrapper ::v-deep .signup-options > * {
    margin-left: auto;
  }
}

.technologies .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--spacing-48);
  padding-bottom: var(--spacing-48);

  .single-technology {
    display: flex;
    align-items: center;
    color: var(--main-dark-shade-4);

    svg {
      width: 24px;
      margin-right: 10px;

      &.sql {
        width: 28px;
      }

      [fill] {
        fill: var(--main-light-shade-4);
      }

      [stroke] {
        stroke: var(--main-light-shade-4);
      }
    }
  }
}

.white.slider {
  ::v-deep .slider-container {
    margin-top: -58px;

    .slider-header {
      min-height: 33px;
    }
  }

  a {
    color: var(--main-dark);
  }

  .img {
    height: 205px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 7px;
    }
  }

  .video .img {
    position: relative;
    cursor: pointer;
    height: 150px;

    &:before {
      content: '';
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      background-color: rgba(0, 12, 31, 0.5);
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all var(--duration) ease-out;
      border-radius: 7px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -19.5px;
      margin-left: -19.5px;
      width: 39px;
      height: 39px;
      background-image: url('../../assets/svg/icon-play-video-circle.svg');
      transition: all var(--duration) ease;
      z-index: 2;
    }

    &:hover {
      &:before {
        opacity: 1;
        visibility: visible;
        z-index: 1;
      }

      &:after {
        opacity: 0.7;
      }
    }
  }

  .blog-post-title-2 {
    font-weight: 500;
    margin: var(--spacing-36) 0 var(--spacing-16);
  }

  .body-2 {
    margin: var(--spacing-12) 0 0;
  }

  .description {
    font-size: 18px;
    line-height: 1.1;
    color: var(--main-dark-shade-3);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
    max-height: calc(18px * 3 * 1.1);
  }

  .caption {
    margin: 0;
    color: var(--main-dark-light-4);
  }

  .btn-arrow {
    font-size: 18px;
    margin-top: var(--spacing-36);
  }
}

.features {
  .heading,
  .h3 {
    color: var(--main-light);
    text-align: center;

    .body-1 {
      color: var(--main-light-shade-4);
    }
  }

  .heading {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .left {
      font-size: 40px;
      line-height: 28px;
    }

    img {
      height: 46px;
      margin-left: 16px;
    }
  }

  .h3 {
    margin-bottom: var(--spacing-36);
  }

  .bg {
    display: block;
    margin: var(--spacing-80) auto var(--spacing-100);
    max-width: 970px;

    img {
      max-width: 100%;
      display: block;
    }
  }

  ul {
    color: var(--main-light);
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;

    + ul {
      margin-top: 64px;
    }

    li {
      display: flex;
      align-items: flex-start;

      svg {
        width: 24px;
        max-height: 24px;
        flex: 0 0 auto;
        margin-right: var(--spacing-16);
      }

      .body-1 {
        margin-top: var(--spacing-16);
        color: var(--main-light-shade-4);
        display: block;
      }
    }
  }

  .section-link {
    margin-top: 80px;
  }
}

.free-resources {
  .heading {
    text-align: center;

    .body-1 {
      margin: var(--spacing-16) 0 0;
      color: var(--main-dark-light-4);
    }
  }

  ::v-deep {
    .heading > * {
      margin-bottom: 0;
    }

    .subtitle {
      margin-left: auto;

      .btn-link-accent {
        font-size: 18px;
        padding-right: 0;
      }
    }

    .controls {
      margin-left: var(--spacing-32);
    }
  }

  .img {
    img {
      border-radius: 10px;
      transition: all var(--duration) ease-out;
    }
  }

  a:hover img {
    transform: scale(1.1);
  }

  ::v-deep .slider-container {
    padding: 0 30px 30px;
    margin: 0 -30px -30px;
  }

  .resources {
    margin-top: 64px;

    ~ * {
      margin-top: 160px;
    }

    .img {
      max-width: 100%;

      img {
        display: block;
        max-width: 100%;
        margin: auto;
      }
    }
  }

  .calculators {
    ::v-deep .slider-container {
      padding: 0 40px 40px;
      margin: 0 -40px -40px;
    }

    ul {
      list-style: none;
      margin-top: 0;
      margin-bottom: 0;

      li {
        transition: all 300ms ease;
        border-radius: 10px;
        position: relative;
        background-color: var(--main-light);
        box-shadow: 0 16px 30px 0 rgba(0, 12, 31, 0.25);

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px;
          border-left: 8px solid var(--main-light-shade-4);
          font-size: 21px;
          line-height: 1.1;
          min-height: 100px;
          font-weight: 500;
          color: var(--main-dark);
          text-align: center;
          border-radius: 10px;
        }

        &:hover {
          transform: scale(1.05);

          a {
            color: var(--accent);
            border-color: var(--accent);
          }
        }

        + li {
          margin-top: var(--spacing-16);
        }
      }
    }
  }

  .blog {
    ::v-deep {
      .slider-container {
        padding: 0 15px 15px;
        margin: 0 -15px -15px;
      }

      .list.sm .article {
        transition: all var(--duration) ease;

        &.vertical {
          padding-top: 0;
        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

.start-learn-today .h3 strong {
  color: var(--accent);
}

@include bp($bp-laptop) {
  .features {
    .bg {
      max-width: 820px;
    }

    .heading {
      .left {
        font-size: 32px;
      }

      img {
        height: 30px;
      }
    }
  }

  .white.slider {
    .img {
      height: 170px;
    }

    .video .img {
      height: 125px;
    }

    .blog-post-title-2 {
      font-size: 24px;
    }
  }
}

@include bp($bp-mobile) {
  section {
    padding-top: 80px;
    padding-bottom: 80px;

    .section-link .btn-primary {
      max-width: 327px;
    }
  }

  .desktop-only {
    display: none !important;
  }

  .heading {
    text-align: center;
  }

  ::v-deep .signup-options .options .title {
    &,
    &.h4 {
      font-size: 24px;
    }
  }

  .h2,
  ::v-deep .h2,
  .h3,
  ::v-deep .h3 {
    font-size: 32px;
  }

  .h4,
  ::v-deep .h4,
  .heading .subtitle,
  ::v-deep .heading .subtitle {
    font-size: 21px;
  }

  .body-1,
  ::v-deep .body-1 {
    font-size: 18px;
  }

  .hero {
    background-image: url('../../assets/images/home-v2-bg-hero-mobile.webp');
    padding-top: 64px;
    padding-bottom: 0;
    min-height: 0;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto 42%;

    h1 {
      font-size: 32px;
    }

    .left {
      padding-top: 80px;
      padding-bottom: 80px;

      .rating-inline {
        display: block;

        .light,
        .rating {
          display: inline-block;
          vertical-align: middle;
        }

        .dark-light {
          display: block;
        }
      }
    }

    .signup-wrapper {
      background-color: var(--main-light);
      padding: 0;
      margin: 0 -12px;
      width: calc(100% + 24px);
      max-width: unset;
      flex: 1;

      ::v-deep .signup-options {
        .options,
        &.form-step .form-wrap {
          padding-top: 80px;
          padding-bottom: 80px;
          min-height: 514px;
          height: auto;
        }
      }
    }
  }

  .technologies {
    .container {
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 0;
      margin-right: 0;

      .single-technology {
        margin: 15px 20px;
      }
    }
  }

  .white.slider {
    ::v-deep .slider-container {
      margin-top: 0;

      .slider-header {
        justify-content: center;

        .controls {
          display: block;
        }
      }
    }

    .blog-post-title-2 {
      font-size: 24px;
    }

    .img {
      height: 248px;
    }
  }

  .features {
    .heading {
      .title {
        display: block;
      }

      .left {
        font-size: 32px;
        display: block;
      }

      img {
        height: 30px;
      }
    }

    .title-ul {
      font-size: 28px;
    }

    ul {
      li {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        + li {
          margin-top: 44px;
        }

        svg {
          margin: 0 0 var(--spacing-12);
        }
      }

      + ul {
        margin-top: 44px;
      }
    }

    .bg {
      margin: var(--spacing-48) auto var(--spacing-80);
    }
  }

  .free-resources {
    .slider-container,
    .calculators .slider-container {
      padding: 0 24px 50px;
      margin: 0 -24px -50px;

      ::v-deep .slider-header {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .controls {
          display: block;
          margin-top: var(--spacing-16);
          margin-left: 0;
        }
      }
    }

    .resources ~ * {
      margin-top: 60px;
    }

    .heading .h3 {
      font-size: 28px;
    }

    .mobile-only {
      display: block;
      margin: var(--spacing-16) 0 0;
    }
  }

  .start-learn-today {
    &.window {
      padding-top: 0;
      padding-bottom: 0;
      background-image: none !important;

      ::v-deep {
        .content {
          background-image: url('../../assets/images/ds-signup-bg.webp');
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 80px 24px;
          margin: 0 -12px;
          width: calc(100% + 48px);
          max-width: unset;
          flex: 1;
          display: block;
          text-align: center;

          .h3 strong {
            display: block;
          }
        }

        .signup-options .options,
        .signup-options.form-step .form-wrap {
          padding: 80px 0;
          min-height: 514px;
          height: auto;
        }
      }
    }
  }
}

</style>
