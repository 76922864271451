<template>
  <section class="steps">
    <div class="container">
      <div class="heading" :class="$config.platform === 'ds' ? 'col-6' : 'col-8'">
        <h2 v-if="title" class="h2">
          {{ title }}
        </h2>
        <p v-if="subtitle" class="subtitle">
          {{ subtitle }}
        </p>
      </div>
      <div class="content">
        <div
          v-for="(opt, i) in options" :key="i" class="row"
          :class="{ reverse: i % 2 }"
        >
          <div class="col sm-6 col-4 text offset-1" :class="i % 2 ? '' : ''">
            <h3 class="h3">
              {{ opt.title }}
            </h3>
            <p class="subtitle">
              {{ opt.subtitle }}
            </p>
            <ButtonArrow
              v-bind="opt.arrowbtnProps"
              :class=" $config.platform === 'ds' ? 'button-arrow' : $store.state.layout === 'desktop' ? 'btn-arrow-light' : 'button-arrow-primary'"
            />
          </div>
          <div class="col sm-6 col-6 animation" :class="i % 2 ? '' : 'offset-1'">
            <Animation :options="{ data: getAnimation(opt.animation), rendererSettings: { viewBoxOnly: true } }" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonArrow from '@itbpbg/lms-components/src/components/ButtonArrow';
import Animation from '../../components/Animation';

export default {
  components: {
    Animation,
    ButtonArrow,
  },
  props: {
    title: { type: String, default: '', required: false },
    subtitle: { type: String, default: '', required: false },
    options: { type: Array, default: () => [], required: true },
  },
  computed: {
    animationTrack() {
      if (this.$config.platform === 'fa') {
        return 'fa/fa-get-on-the-right-track';
      }
      return 'get-on-the-right-track';
    },
    animationExams() {
      if (this.$config.platform === 'fa') {
        return 'fa/fa-pass-the-exams';
      }
      return 'pass-the-exams';
    },
    animationCertificate() {
      if (this.$config.platform === 'fa') {
        return 'fa/fa-receive-certificate';
      }
      return 'receive-certificate';
    },
    animationResume() {
      if (this.$config.platform === 'fa') {
        return 'fa/fa-create-professional-resume';
      }
      return 'create-professional-resume';
    },
  },
  methods: {
    getAnimation(a) {
      return this[a];
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  position: relative;
  background-color: var(--main-dark);
  margin-top: var(--spacing-120);
  padding: var(--spacing-110) 0;
  color: var(--main-light);

  &:before {
    content: '';
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(225.11deg, rgba(0, 164, 178, 0.14) 19.77%, rgba(0, 164, 178, 0.05) 36.77%, rgba(0, 12, 31, 0) 63.97%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .container {
    position: relative;
    z-index: 1;
    text-align: center;

    .heading {
      margin: 0 auto;
    }

    .subtitle {
      color: var(--main-light-shade-4);
    }

    > .subtitle {
      margin: var(--spacing-16) 0 0;
    }
  }

  .content {
    margin-top: var(--spacing-100);

    .row {
      align-items: center;

      + .row {
        margin-top: 134px;

        &.reverse {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }
      }

      .text {
        text-align: left;

        .h3 {
          margin: 0;
        }

        .subtitle {
          margin: var(--spacing-16) 0 0;
        }

        .button-arrow {
          margin-top: var(--spacing-40);
          font-size: 18px;
        }
      }

      .animation {
        padding-right: calc(28px + var(--gutter) / 2);
      }
    }
  }
}

.fa {
  .steps {
    padding-bottom: var(--spacing-10);

    &:before {
      background: linear-gradient(226.18deg, rgba(3, 111, 253, 0.2) 6.59%, rgba(3, 111, 253, 0.05) 31.71%, rgba(3, 111, 253, 0) 59.79%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .subtitle {
      margin-top: var(--spacing-32);
    }

    .content .row .text .subtitle {
      margin-bottom: var(--spacing-48);
    }
  }
}

@include bp($bp-mobile) {
  .steps {
    padding-bottom: 0;

    .content {
      margin-top: 50px;

      .row {
        .animation {
          padding-right: 15px;
        }

        .text {
          padding-right: 15px;
        }

        +.row {
          margin-top: var(--spacing-80);
        }
      }
    }
  }

  .fa {
    .steps {
      .content {
        .row {
          margin-bottom: var(--spacing-60);

          +.row {
            margin-top: var(--spacing-60);
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          .text {
            margin-top: var(--spacing-40) !important;

            .subtitle {
              margin-bottom: var(--spacing-40);
            }
          }
        }
      }
    }
  }
}

@include bp($bp-phone) {
  .steps {
    .content .row {
      .col.text {
        margin-top: var(--spacing-36);
      }
    }
  }
}
</style>
