<template>
  <div class="pre-footer" :class="className">
    <div class="container">
      <div class="holder row">
        <div class="col" :class="[offset ? `offset-${offset}` : '', columns ? columns : '', mdcolumns ? mdcolumns : '', mdoffset ? `offset-${mdoffset}` : '']">
          <p class="title" :class="heading">
            {{ title }}
          </p>
          <p class="description">
            {{ description }}
          </p>
          <nuxt-link to="/signup/" class="btn-primary footer-btn" data-event="start_your_career_4.click">
            {{ buttonTitle }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: { type: String, default: 'h2' },
    title: { type: String, required: true },
    description: { type: String, default: '', required: false },
    buttonTitle: { type: String, default: '', required: false },
    offset: { type: Number, default: 0 },
    mdoffset: { type: Number, default: 0 },
    columns: { type: String, default: 'col-4' },
    mdcolumns: { type: String, default: '' },
    className: { type: String, default: '' },
  },
};
</script>

<style lang="scss" scoped>
.pre-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 650px;
  background-image: url("../assets/images/fa/fa-pre-footer-homepage.webp");
  background-position-x: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  &.projects-pre-footer {
    background-image: url("../assets/images/ds-pre-footer-projects.webp");
    height: 706px;

    .holder {
      .title {
        line-height: 110%;
        font-size: 32px;
      }

      .description {
        font-size: 21px;
      }

      .footer-btn {
        max-width: 225px;
      }
    }
  }

  &.top-shadow {
    &:before {
      content: "";
      background: linear-gradient(180deg, #011633 0%, rgba(1, 22, 51, 0) 100%);
      width: 100%;
      height: 186px;
      position: absolute;
      top: -1px;
    }
  }

  .holder {
    justify-content: center;
    height: 100%;
    text-align: center;

    .title,
    .description {
      margin: 0;
    }

    .title {
      color: var(--main-light);
      margin-bottom: var(--spacing-16);
    }

    .description {
      color: var(--accent-light-2);
    }

    .footer-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: var(--spacing-48) auto 0;
      max-width: 300px;
      width: 100%;
      height: 58px;
    }
  }
}

@include bp($bp-laptop) {
  .pre-footer .holder .footer-btn {
    max-width: 225px;
  }
}

@include bp($bp-mobile) {
  .pre-footer {
    height: unset;
    padding: var(--spacing-60) 0;
    background-position: top;

    &.projects-pre-footer {
      height: 376px;

      .holder {
        .title {
          font-size: 28px;
          width: 100%;
        }

        .description {
          padding: 0;
        }

        .footer-btn {
          max-width: 327px;
        }
      }
    }

    &.top-shadow {
      &:before {
        height: 77px;
      }
    }

    .holder {
      .title {
        font-size: 28px;
        width: 80%;
        margin: 0 auto var(--spacing-16);
      }

      .description {
        font-size: 16px;
        width: 100%;
        padding: 0 var(--spacing-24);
      }
    }
  }
}

.fa .pre-footer.projects-pre-footer {
  background-image: url("../assets/images/fa/fa-pre-footer-projects.webp");
}
</style>
