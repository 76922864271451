<template>
  <div class="signup-options" :class="{ 'form-step': step === 1 }">
    <ProfileOptions
      v-if="step === 0"
      class="options"
      :title="title"
      :first-step-title="firstStepTitle"
      :type="type"
      :event-prefix="eventPrefix"
      heading="h1"
      @next-step="nextStep"
    >
      <div slot="footer" class="footer">
        Already have an account?
        <span v-if="resources" @click="$emit('login')">
          Log In
        </span>
        <nuxt-link v-else :to="`/login/${params}`">
          Log In
        </nuxt-link>
      </div>
    </ProfileOptions>
    <SignupForm
      v-if="step === 1"
      v-bind="$props"
      class="form-wrap"
      back-button
      hide-socials
      @prev-step="prevStep"
    />
  </div>
</template>

<script>
import ProfileOptions from '../ProfileOptions';
import SignupForm from '../../SignupForm';

export default {
  components: {
    ProfileOptions,
    SignupForm,
  },
  props: {
    title: { type: String, default: '' },
    firstStepTitle: { type: String, required: false, default: '' },
    resources: Boolean,
    tracking: { type: String, default: '' },
    buttonText: { type: String, default: '' },
    className: { type: String, default: '' },
    type: { type: String, default: '' },
    uniqueId: { type: String, default: '' },
    eventPrefix: { type: String, default: '' },
  },
  data() {
    return {
      step: 0,
    };
  },
  computed: {
    params() {
      return this.$route.fullPath.replace(this.$route.path, '') || '';
    },
  },
  methods: {
    nextStep() {
      this.step += 1;
    },
    prevStep() {
      this.step -= 1;
    },
  },
};
</script>

<style lang="scss">
  .signup-options {
    &.form-step {
      flex-grow: 1;

      .form-wrap {
        padding-top: var(--spacing-48);
        height: 514px;
      }
    }

    .options {
      background-color: var(--main-light);
      border-radius: 10px;
      padding: var(--spacing-48);
      max-width: 446px;
      width: 100%;
    }
  }

  @include bp($bp-laptop) {
    .signup-options {
      &.form-step {
        .form-wrap {
          height: 503px;
        }
      }

      .options {
        padding: var(--spacing-44);
      }
    }
  }

  @include bp($bp-mobile) {
    .signup-options {
      .options {
        margin: 0 auto;
        padding: var(--spacing-24);
      }

      &.form-step {
        .form-wrap {
          padding-top: var(--spacing-24);
          margin: 0 auto;
        }
      }

      .form-wrap {
        .header .h4 {
          padding-left: var(--spacing-8);
        }
      }
    }
  }
</style>
