<template>
  <div>
    <HomePageV2
      v-if="$config.platform === 'ds'"
      :data="pageData"
    />
    <HomePage
      v-else
      :data="pageData"
    />
  </div>
</template>

<script>
import global from '@itbpbg/lms-components/src/mixins/global';
import HomePage from '../components/Home/HomePage';
import HomePageV2 from '../components/Home/HomePageV2';

export default {
  components: {
    HomePage,
    HomePageV2,
  },
  mixins: [global],
  async asyncData({
    $axios, route, $config,
  }) {
    const pageData = await $axios.$get('/pages/data/home/v2').catch(() => ({ page: {} }));

    pageData.page.fullPath = `${$config.siteUrl}${route.fullPath}`;
    pageData.page.metaFullImageUrl = `${$config.siteUrl}/resources/${pageData.page.metaImage}`;
    pageData.page.created = pageData.page.created || new Date();
    pageData.page.updated = pageData.page.updated || new Date();

    return { pageData };
  },
  head() {
    const linkedData = this.$linkedDataLoad([
      { key: 'Organization' },
      { key: 'WebSite' },
      { key: 'WebPage', values: this.pageData.page },
    ]);

    return {
      titleTemplate: `${this.pageData.page.metaTitle}%s`,
      link: [
        { rel: 'canonical', href: this.canonicalURL() },
      ],
      meta: this.metaProps(this.pageData.page),
      script: [linkedData],
    };
  },
};
</script>
