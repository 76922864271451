<template>
  <SlidingAnimation class="home-hero-animation">
    <div class="row">
      <div
        v-for="(group, i) in instructors"
        :key="i"
        class="col col-4 sm-4"
      >
        <div
          v-for="(instructor, idx) in group"
          :key="idx"
          class="instructor"
          style="--ratio: calc(275 / 355);"
        >
          <img :src="`${$config.imagesUrl}/team/${instructor.image}`" :alt="instructor.name">
        </div>
      </div>
    </div>
    <div class="row">
      <div
        v-for="(group, i) in instructors"
        :key="i"
        class="col col-4 sm-4"
      >
        <div
          v-for="(instructor, idx) in group"
          :key="idx"
          class="instructor"
          style="--ratio: calc(275 / 355);"
        >
          <img :src="`${$config.imagesUrl}/team/${instructor.image}`" :alt="instructor.name">
        </div>
      </div>
    </div>
  </SlidingAnimation>
</template>

<script>
import SlidingAnimation from '@itbpbg/lms-components/src/Animations/Sliding';

const instructors = [
  // first column
  { name: 'Bernard Marr', image: 'hhsn-bernard-marr.webp' },
  { name: 'Elitsa Kaloyanova', image: 'hhsn-elitsa-kaloyanova.webp' },
  { name: 'Olivier Maugain', image: 'hhsn-olivier-maugain.webp' },
  { name: 'Anastasia Kuznetsova', image: 'hhsn-anastasia-kuznetsova.webp' },
  // second column
  { name: 'Davis Balaba', image: 'hhsn-davis-balaba.webp' },
  { name: 'Ken Jee', image: 'hhsn-ken-jee.webp' },
  { name: 'Harpreet Sahota', image: 'hhsn-harpreet-sahota.webp' },
  { name: 'Randy Rosseel', image: 'hhsn-randy-rosseel.webp' },
  // third column
  { name: 'Danielle Thé', image: 'hhsn-danielle-the.webp' },
  { name: 'Andrew Treadway', image: 'hhsn-andrew-treadway.webp' },
  { name: 'Tina Huang', image: 'hhsn-tina-huang.webp' },
  { name: 'Giles McMullen', image: 'hhsn-giles-mcmullen.webp' },
];

export default {
  components: { SlidingAnimation },
  computed: {
    instructors() {
      const cols = this.$store.state.layout === 'desktop' || this.$store.state.tablet ? 3 : 2;

      const chunkSize = Math.ceil(instructors.length / cols);
      const instructorsArray = [];
      for (let i = 0; i < instructors.length; i += 1) {
        const chunkIndex = Math.floor(i / chunkSize);

        if (!instructorsArray[chunkIndex]) {
          instructorsArray[chunkIndex] = [];
        }

        instructorsArray[chunkIndex].push(instructors[i]);
      }

      for (let i = 0; i < instructorsArray.length; i += 1) {
        instructorsArray[i] = [...instructorsArray[i]];
      }

      return instructorsArray;
    },
  },
};
</script>

<style lang="scss">
.home-hero-animation {
  .instructor {
    overflow: hidden;
    margin-bottom: 16px;
    background-size: cover;
    background-position: center;
    position: relative;

    img {
      border-radius: 7px;
      width: 100%;
      max-width: 100%;
      display: block;
    }
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;
  }

  .col {
    padding-left: 8px;
    padding-right: 8px;

    &:nth-child(1),
    &:nth-child(3) {
      margin-top: -100px;
    }
  }

  @include bp($bp-phone) {
    .col {
      width: 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
