<template>
  <a
    href="#social-login"
    :title=" formType === 'login' ? 'Login with LinkedIn' : 'Sign up with LinkedIn'"
    :class="{ 'no-bg': buttonText }"
    rel="nofollow"
    v-on="formType === 'login' ? { click: linkedinLogin } : { click: socialSignUp }"
  >
    <template v-if="buttonText">
      <IconLinkedin /> {{ buttonText }}
    </template>
  </a>
</template>
<script>
import IconLinkedin from '../assets/svg/icon-colored-linkedin-fill.svg?vue-component';

export default {
  components: {
    IconLinkedin,
  },
  props: {
    keepMeLoggedIn: Boolean,
    errored: Boolean,
    campaign: Boolean,
    termsAgreed: Boolean,
    formType: { type: String, default: '' },
    buttonText: { type: String, default: '' },
  },
  methods: {
    async linkedinLogin() {
      await this.$root.$emit('check');
      let routePath = 'login';
      let baseRoutePath = '';
      const isFlashcardsRoute = this.$route.name === 'flashcards' || this.$route.fullPath.startsWith('/flashcards');
      if (isFlashcardsRoute) {
        baseRoutePath = this.$route.path.replace(/^\/|\/$/g, '');
        routePath = 'flashcards';
      } else if (this.$route.name === 'resources-center-exams-signup') {
        routePath = this.$route.path.replace(/^\/|\/$/g, '');
      }

      const queryParams = Object.entries({
        routePath: baseRoutePath || routePath,
        ...this.$route.query,
        sessionId: this.$store.state.tracking.sessionId,
        visitorId: this.$tracking.getVisitorId(),
        ...this.$tracking.getAsyncEvents()[this.$el.dataset.event].payload,
        formType: this.formType,
        signupType: 'linkedin',
      }).map((i) => `${i[0]}=${encodeURIComponent(i[1] || '')}`).join(',');

      if (this.formType === 'login' || (this.formType === 'signup' && !this.errored && process.client)) {
        const linkedinUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&scope=${encodeURI('r_liteprofile r_emailaddress')}&client_id=${this.$config.linkedinApiKey}&redirect_uri=${encodeURI(`${this.$config.siteUrl}/${routePath}&state=${queryParams}`)}`;
        window.location = linkedinUrl;
      }
    },
    checkTerms() {
      this.$root.$emit('check-terms', true);
    },
    socialSignUp() {
      if (this.termsAgreed) {
        this.linkedinLogin();
      }
      this.checkTerms();
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  background-image: url('../assets/svg/icon-colored-linkedin-fill.svg');
  image-rendering: -webkit-optimize-contrast;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0;
  color: var(--main-dark-shade-0);
  display: block;
  line-height: 50px;
  height: 100%;
  text-align: center;
  width: 100%;

  &.no-bg {
    background-image: none;
  }
}
</style>
