<template>
  <section class="slider-tracks container">
    <Slider
      v-if="careerTracks.length "
      :hidden="hidden"
      v-bind="$attrs"
    >
      <h2 slot="heading" class="h3" :class="[columns]">
        {{ title }}
      </h2>
      <div
        v-for="(track, i) in careerTracks"
        :key="i"
        slot="slide"
        class="col col-4 sm-6"
      >
        <component
          :is="TrackCard"
          certificate
          :track="track"
          align
        />
      </div>
    </Slider>
  </section>
</template>
<script>
import Slider from '@itbpbg/lms-components/src/components/Slider';

export default {
  components: { Slider },
  props: {
    title: { type: String, default: 'Choose the Best Career Track for You' },
    darkTheme: { type: Boolean },
    disabled: { type: Boolean },
    hidden: { type: Boolean },
    tracks: { type: Array, default: () => ([]) },
    columns: { type: String, default: 'col-5' },
  },
  data() {
    return {
      tracksList: [],
    };
  },
  async fetch() {
    if (!this.tracks.length) {
      this.tracksList = await this.$axios.$get('/career-tracks').catch(() => false);
    }
  },
  computed: {
    careerTracks() {
      if (this.tracks.length) {
        return this.tracks;
      }

      return this.tracksList;
    },
    TrackCard() {
      if (this.$config.platform === 'fa') {
        return () => import('@itbpbg/lms-components/src/Career-tracks/FaTrackCard');
      }
      return () => import('@itbpbg/lms-components/src/Career-tracks/TrackCard');
    },
  },
};
</script>
<style lang="scss" scoped>
.slider-tracks {
  padding-bottom: 0;

  .h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .slider-cards {
    margin-top: var(--spacing-36);
  }

  .slider-container {
    ::v-deep .slider-header {
      .heading {
        width: 100%;
      }
    }

    ::v-deep .swiper {
      .track-card {
        height: 100%;
      }
    }

    ::v-deep .dots {
      padding: var(--spacing-36) 0;
    }
  }
}

@include bp($bp-mobile) {
  .slider-tracks {
    padding-left: 24px;
    padding-right: 24px;

    .h3 {
      max-width: 100%;
    }
  }
}

@include bp($bp-phone) {
  .slider-tracks {
    background: var(--main-light-shade-1);

    .slider-container {
      ::v-deep .dots {
        padding-bottom: var(--spacing-80);
      }
    }
  }
}
</style>
